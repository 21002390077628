<template>
  <div id="divTotalLossWrapper" class="content-layout-fixer">
    <Header :text="headerContent[0].text" :headline="headerContent[0].headline"></Header>
    <VueWrapperNarrow>
      <VueForm class="form-holder" ref="form">
        <div v-for="(item, index) in formModel" :key="index">
          <VueInput
            class="input-wrapper"
            :name="item.name"
            :id="item.name"
            type="text"
            :label="item.label"
            v-model="item.amount"
            :messageOnTop="true"
            validationRules="numeric"
            validatorName="Ürün"
          ></VueInput>
        </div>
      </VueForm>
      <BrandButton
        class="calc-button"
        :size="sizes.xxLarge"
        :contentAlignment="constants.flexAlignment.center"
        type="submit"
        @click="handleSubmit"
        :disabled="isDisabled"
        >HESAPLA</BrandButton
      >
      <div v-if="showResultView" class="result-holder">
        <VueText class="total-loss-person" color="grey-40" sizeLevel="12" weightLevel="3">
          {{ resultViewItems.topValue | currencyFormat }}</VueText
        >
        <VueText class="customer-total-loss" color="grey-40" sizeLevel="11">
          {{ resultViewItems.topTitle }}</VueText
        >
        <VueText class="total-loss-amount" color="grey-40" sizeLevel="12" weightLevel="3">
          {{ resultViewItems.bottomValue | currencyFormat }} TL</VueText
        >
        <VueText class="turnover-total-loss" color="grey-40" sizeLevel="11">
          {{ resultViewItems.bottomTitle }}</VueText
        >
      </div>
      <BrandButton
        :outlined="true"
        v-if="showResultView"
        @click="submitResultPopUp"
        :size="sizes.xxLarge"
        >PERFORMANS TAKİBİ</BrandButton
      >
    </VueWrapperNarrow>
  </div>
</template>

<script>
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import Header from '@/components/brand/Headers/Header.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { Learn } from '@/services/Api/index';
import currencyFilter from '@/mixins/currencyFilter.js';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'TotalLoss',
  components: {
    Header,
    VueForm,
    VueInput,
    BrandButton,
    VueWrapperNarrow,
    VueText,
  },
  mixins: [currencyFilter, gtmUtils],
  data() {
    return {
      isDisabled: true,
      showResultView: false,
      resultViewItems: {
        topTitle: 'Ürün satamadığınız günlük müşteri sayısı',
        topValue: 0,
        bottomTitle: 'Yıllık Ciro Kaybınız',
        bottomValue: 0,
      },
      formModel: {
        marlboro: {
          name: 'marlboro',
          label: 'Marlboro',
          productFamily: 'ML',
          amount: '',
        },
        parliament: {
          name: 'parliament',
          label: 'Parliament',
          productFamily: 'PL',
          amount: '',
        },
        chesterfield: {
          name: 'chesterfield',
          label: 'Chesterfield',
          productFamily: 'CH',
          amount: '',
        },
        lark: {
          name: 'lark',
          label: 'Lark',
          productFamily: 'LA',
          amount: '',
        },
        lm: {
          name: 'lm',
          label: 'L&M',
          productFamily: 'LM',
          amount: '',
        },
        muratti: {
          name: 'muratti',
          label: 'Muratti',
          productFamily: 'MU',
          amount: '',
        },
      },
      headerContent: [
        {
          headline: 'Yok satarak ne kadar kaybediyorsunuz?',
          text:
            'Günde kaç Philip Morris tüketicisini ürün kalmadığı için geri çevirmek zorunda kalıyorsunuz?',
        },
      ],
    };
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        let count = 0;
        Object.keys(this.formModel).forEach(key => {
          if (this.formModel[key].amount && this.formModel[key].amount.length > 0) {
            count++;
          }
          this.isDisabled = count === 0;
        });
      },
    },
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    async handleSubmit() {
      let items = [];
      Object.values(this.formModel).map(model => {
        if (model.amount && model.amount.length && !isNaN(parseInt(model.amount))) {
          items.push({ ...model, amount: parseInt(model.amount) });
        }
      });
      Learn.getTotalLoss(items).then(res => {
        let response = res.data.Data;
        if (response) {
          this.resultViewItems.topValue = response.dailyCustomerCount;
          this.resultViewItems.bottomValue = response.annualRevenueLoss;
          this.showResultView = true;
          this.pushDataLayerEvent('learn', {
            event: 'yok_kayip',
            ...Object.fromEntries(
              Object.entries(this.formModel).map(([key, val]) => [key, Number(val.amount)]),
            ),
            daily_notsales_customer: response.dailyCustomerCount,
            yearly_revenue_lost: response.annualRevenueLoss,
          });
          this.$nextTick(() => {
            const result = document.querySelector('.result-holder');
            result.scrollIntoView({ behavior: 'smooth' });
          });
        }
      });
    },
    submitResultPopUp() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.ManageMyBusiness.path}`);
    },
  },
};
</script>

<style lang="scss">
.form-holder {
  margin-top: palette-space-level(60);

  .input-wrapper {
    margin-bottom: palette-space-level(20);
  }
}

.calc-button {
  margin-top: palette-space-level(10);
}

.result-holder {
  padding: palette-space-level(30) 0 palette-space-level(45);
  margin: palette-space-level(70) 0 palette-space-level(30);
  background-color: palette-color-level('grey', '10');
  text-align: center;

  .total-loss-person,
  .total-loss-amount {
    padding-bottom: palette-space-level(30);
  }

  .customer-total-loss {
    margin-bottom: palette-space-level(80);
  }
}
</style>
